.offcanvas.offcanvas-end {
    padding: 20px;
    box-shadow: -6px 0 10px -6px rgba(12, 35, 67, 0.97);

.offcanvas-header .h5{
    font-size: 1.25rem!important;
    font-family: 'Praktika Extra Bold', Arial, Helvetica, sans-serif !important;
    letter-spacing: 0.15625rem!important;
    text-transform: uppercase!important;
    color: #0c2340!important;
}

.form-containers-filter-custom {
    margin-bottom: 20px;

    color: #0C2340;

    .form-label {
        width: 100%;
        font-size: 0.875rem;
        font-family: 'Praktika Bold', Arial, Helvetica, sans-serif;
        letter-spacing: 0.15625rem!important;
        text-transform: uppercase;
    }

    .form-select{
        border: 2px solid #0c2340;

    }

    .form-check {
        text-transform: capitalize;
        padding-bottom: 5px;

    }

    .form-check-input:checked {
        background-color: #7d98c1;
        border-color: #204275;
    }

}

//Home Away In 3 Rows

.haForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

//Days of The Week In 2 Rows
.wdForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-check {

        width: 50%;
    }
}

}

.primaryBtnFilter {
    width: 100%;
    margin: 5px;
    border: 2px solid  #0C2340;
    border-radius: 5px;
    text-align: center;
    background-color:  #0C2340;
    color: #fff !important;
    margin-bottom: 1rem;
}


.primaryBtnFilter {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.15625rem!important;
    font-family: 'Praktika Bold', Arial, Helvetica, sans-serif;
    padding: 10px 5px 10px 5px;
    appearance: none; // Just in case we missed anything.

}

//mobile
@media (max-width: 767px) {
.offcanvas {
    max-width: 88%!important;
}
}
//quick fix for sticky header
div[class*=css_pageContent__] {
        overflow-x: clip;
        overflow-clip-margin: 0px;
}

.ms-Dropdown-callout {
    min-width: 150px;
}
