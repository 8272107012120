@use "../../../../../styles/etc/env" as *;

.NBAPageContent {
	margin-top: 1rem;
	padding: 1rem;

	:global {
		a {
			color: $bealeStreetBlue;
			font-family: "Praktika Bold", Arial, Helvetica, sans-serif;
			font-weight: normal;
			text-decoration: none;
			&:hover {
				color: $bealeStreetBlue;
				text-decoration: underline;
			}
		}
	}
}

.scrollTop {
	display: block;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 0.375rem;
	position: fixed;
	font-size: 0 !important;
	background-color: $gold;
	background-image: url(https://s3.grizzliesapp.com/assets/img/top-arrow-midnight.svg);
	background-size: 18px;
	background-position: 50%;
	background-repeat: no-repeat;
	bottom: 1.25rem;
	left: auto;
	right: -3rem;
	border: none;
	z-index: 1090;
	opacity: 1;
	transition: right 0.3s ease-in-out;
	box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 0.06), 0px 1px 1px -0.5px rgb(0 0 0 / 0.06),
		0px 3px 3px -1.5px rgb(0 0 0 / 0.06), 0px 6px 6px -3px rgb(0 0 0 / 0.06),
		0px 12px 12px -6px rgb(0 0 0 / 0.06), 0px 24px 24px -12px rgb(0 0 0 / 0.06);

	&:hover {
		box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 0.06), 0px 1px 1px -0.5px rgb(0 0 0 / 0.06),
			0px 3px 3px -1.5px rgb(0 0 0 / 0.12), 0px 6px 6px -3px rgb(0 0 0 / 0.12),
			0px 12px 12px -6px rgb(0 0 0 / 0.12), 0px 24px 24px -12px rgb(0 0 0 / 0.12);
	}

	&.in {
		right: 1rem;
	}

	&.bottomUp {
		bottom: 5rem;
	}
}

@media only screen and (min-width: 768px) {
	.scrollTop {
		&.in {
			right: 1.25rem;
		}
	}
}

@media (min-width: 1200px) {
	.pageContent {
		:global {
			h1 {
				font-size: 3rem;
			}
		}
	}
}
