@use "../../../../../styles/etc/env" as *;

.pageContent {
	font-family: "Praktika Medium", Arial, Helvetica, sans-serif;
	font-size: 1.2rem;
	color: $memphisMidnight;

	:global {
		.page-bg {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			z-index: -1;
		}
	}
}

@media (min-width: 768px) {
	.pageContent {
		:global {
			// Fixes bootstrap override of tailwind classes on desktop
			#nba-footer {
				nav {
					text-align: left !important;
				}
			}
		}
	}
}
