$s3BaseURL: "https://s3.grizzliesapp.com/";
$bealeStreetBlue: #7d98c1;
$ADA_bealeStreetBlue_onWhite: #4e7aa6;
$bealeStreetBlueHover: #204275;
$memphisMidnight: #0c2340;
$memphisMidnightHover: #010b18;
$gold: #ffc72c;
$goldActive: #fdba00;
$gray: #707372;
$boxShadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$smallRadius: 1rem;
$greenFeatured: rgb(90, 190, 190);
$titanium: #4a4f64;